import { useEffect, useRef, useState } from 'react';

export function usePreviousDistinctValue<T>(value: T): T | undefined {
    const [previousValue, setPreviousValue] = useState<T | undefined>(undefined);
    const currentRef = useRef<T>(value);

    useEffect(() => {
        if (!Object.is(currentRef.current, value)) {
            setPreviousValue(currentRef.current);
            currentRef.current = value;
        }
    }, [value]);

    return previousValue;
}
