/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import isEqual from 'lodash.isequal';

export const isArguments = (value: unknown): value is IArguments => ({}).toString.call(value) === `[object Arguments]`;
export const isNumber = (value: unknown): value is number => ({}).toString.call(value) === `[object Number]`;
export const isRegExp = (value: unknown): value is RegExp => ({}).toString.call(value) === `[object RegExp]`;
export const isString = (value: unknown): value is string => ({}).toString.call(value) === `[object String]`;
export const isSymbol = (value: unknown): value is symbol => ({}).toString.call(value) === `[object Symbol]`;
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export const isFunction = (value: unknown): value is Function => ({}).toString.call(value) === `[object Function]`;
export const isDate = (value: unknown): value is Date => value instanceof Date;
export const isError = (value: unknown): value is Error => value instanceof Error;
export const isMap = <K, V>(value: unknown): value is Map<K, V> => value instanceof Map;
export const isSet = <T>(value: unknown): value is Set<T> => value instanceof Set;
export const isWeakMap = <K extends object, V>(value: unknown): value is WeakMap<K, V> => value instanceof WeakMap;
export const isWeakSet = <T extends object>(value: unknown): value is WeakSet<T> => value instanceof WeakSet;

export const { isArray } = Array;
export const isObject = (value: unknown): value is object => ({}).toString.call(value) === '[object Object]';
export const isUndefined = (value: unknown): value is undefined => typeof value === 'undefined';

export const isEmpty = (value: unknown): boolean => {
    if (value === '0' || !value) {
        return true;
    }

    if (typeof value === 'object') {
        return Object.keys(value).length === 0;
    }

    return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isIterable = (object: any) => object != null && typeof object[Symbol.iterator] === 'function';

const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);

export const guid = () => `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;

export const deepClone = <T>(obj: T): T => {
    // Handle null early
    if (obj === null || obj === undefined) {
        return obj;
    }

    // Handle other primitive types
    if (typeof obj !== 'object') {
        return obj;
    }

    // Handle special object types
    if (isArray(obj)) {
        return obj.map((item) => deepClone(item)) as T;
    }

    if (isDate(obj)) {
        return new Date(obj) as unknown as T;
    }

    if (isMap(obj)) {
        return new Map([...obj].map(([k, v]) => [k, deepClone(v)])) as unknown as T;
    }

    if (isSet(obj)) {
        return new Set([...obj].map((item) => deepClone(item))) as unknown as T;
    }

    if (!isObject(obj)) {
        return obj;
    }
    // Handle plain objects
    const clone = {} as Record<string, unknown>;

    Object.entries(obj).forEach(([key, value]) => {
        clone[key] = deepClone(value);
    });

    return clone as T;
};

export const isEqualObject = (object1: unknown, object2: unknown) => isEqual(object1, object2);

/**
 * Performs a deep merge of `source` into `target`.
 * Mutates `target` only but not its objects and arrays.
 */
export const deepMerge = <T extends object, U extends object>(source: T, target: U): T & U => {
    // Handle edge cases
    if (isUndefined(source) && isUndefined(target)) return source as T & U;
    if (isUndefined(source)) return deepClone(target) as T & U;
    if (isUndefined(target)) return deepClone(source) as T & U;

    // Create a new object with properties from both source and target
    const result = deepClone(source) as T & U;

    Object.entries(target).forEach(([key, value]) => {
        const sourceValue = (source as Record<string, unknown>)[key];

        if (isObject(value) && isObject(sourceValue)) {
            // Recursively merge nested objects
            result[key as keyof (T & U)] = deepMerge(sourceValue, value) as (T & U)[keyof (T & U)];
        } else if (isArray(value) && isArray(sourceValue)) {
            // Merge arrays by concatenating and removing duplicates
            result[key as keyof (T & U)] = [...new Set([...sourceValue, ...value])] as (T & U)[keyof (T & U)];
        } else {
            // For primitive values or when types don't match, prefer target's value
            result[key as keyof (T & U)] = value as (T & U)[keyof (T & U)];
        }
    });

    return result;
};

export const isInsideBounds = (element: HTMLElement, container: HTMLElement) => {
    const elementRect = element?.getBoundingClientRect() || {};
    const containerRect = container?.getBoundingClientRect() || {};
    return !(elementRect.top < containerRect.top || elementRect.bottom > containerRect.bottom);
};

export const isDOMElement = (element: unknown) => element instanceof Element || element instanceof Document;

export const hasImageExtensionFileName = (fileName: string) => {
    const fileNameParts = (fileName || '').split('.');

    return ['jpeg', 'jpg', 'bmp', 'png', 'gif'].includes(fileNameParts[fileNameParts.length - 1]);
};

export const executeFunctionByName = (functionName: string, context?: Record<string, unknown>, ...args: unknown[]) => {
    const namespaces = functionName.split('.');
    const func = namespaces.pop()!;
    let i = 0;
    let currentContext = context!;

    for (; i < namespaces.length; i += 1) {
        currentContext = currentContext?.[namespaces[i]] as Record<string, unknown>;
        if (typeof currentContext === 'undefined') {
            return null;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type, @typescript-eslint/no-unsafe-call
    return (currentContext?.[func] as Function)?.(...args);
};

export const isIE = (): boolean => {
    const ua = window?.navigator?.userAgent || '';
    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');

    if (msie > 0 || trident > 0) {
        return true;
    }

    return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Queue = function Queue(this: any) {
    this.lastPromise = null;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.add = function addFn(obj: any, method: string, args: unknown[], context?: any) {
        this.lastPromise = new Promise((resolve) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            const queueDeferred = this.setup();
            let currentContext = context;
            if (currentContext === undefined || !currentContext) {
                currentContext = obj;
            }

            // execute next queue method
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            queueDeferred.done(() => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                obj[method]
                    .apply(context, args)
                    .then(() => {
                        resolve(undefined);
                    })
                    .catch(() => {
                        resolve(undefined);
                    });
            });
        });
    };

    this.setup = function setupFn() {
        return new Promise((resolve) => {
            // when the previous method returns, resolve this one
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            this.lastPromise
                .then(() => {
                    resolve(undefined);
                })
                .catch(() => {
                    resolve(undefined);
                });
        });
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <T extends (...args: any[]) => any>(func: T, waitTime: number, immediate?: boolean) => {
    let timeout: NodeJS.Timeout | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let result: any;
    let previous: number;
    let context: unknown = null;
    let args: unknown[] | null = null;

    const later = () => {
        const passed = Date.now() - previous;

        if (waitTime > passed) {
            timeout = setTimeout(later, waitTime - passed);
        } else {
            timeout = null;

            if (!immediate) {
                result = func.apply(context, args ?? []);
            }
            // This check is needed because `func` can recursively invoke `debounced`.
            if (!timeout) {
                args = null;
                context = null;
            }
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const debounced = (...newArgs: any[]) => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        context = this;
        args = [...newArgs];
        previous = Date.now();

        if (!timeout) {
            timeout = setTimeout(later, waitTime);

            if (immediate) {
                result = func.apply(context, args);
            }
        }

        return result;
    };

    debounced.cancel = () => {
        if (timeout !== null) {
            clearTimeout(timeout);
        }

        timeout = null;
        args = null;
        context = null;
    };

    return debounced;
};

export const sortByProperty =
    <T extends object>(prop: keyof T, ascending = true): ((objectA: T, correctObjectB: T) => number) =>
    (objectA: T, objectB: T) => {
        const correctObjectA = ascending ? objectA : objectB;
        const correctObjectB = ascending ? objectB : objectA;

        // Check if the property exists and is not undefined in both objects.
        if (
            prop in correctObjectA &&
            !isUndefined(correctObjectA[prop]) &&
            prop in correctObjectB &&
            !isUndefined(correctObjectB[prop])
        ) {
            // Check if the property value is a date.
            if ((correctObjectA[prop] as unknown) instanceof Date) {
                return (correctObjectA[prop] as Date).getTime() - (correctObjectB[prop] as Date).getTime();
            }

            // Use the default sorting function for numbers if the property value is a number,
            // and use the default string sorting function if the property value is a string.
            if (isNumber(correctObjectA[prop])) {
                return (correctObjectA[prop] as number) - (correctObjectB[prop] as number);
            }

            return (correctObjectA[prop] as string).localeCompare(correctObjectB[prop] as string);
        }

        // If the property is undefined in one or both objects,
        return isUndefined(correctObjectA[prop]) ? 1 : -1;
    };

export const uid = () => s4() + s4();

export const uniq = <T>(arr: T[]): T[] => [...new Set(arr)];

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const voidFn = () => {};

export const memoize = <T extends (...args: unknown[]) => unknown>(fn: T, hashFn?: (...args: unknown[]) => string) => {
    const cache = new Map();
    const cached = function (this: unknown, ...args: unknown[]) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const address = `${hashFn ? hashFn.apply(this, args) : args[0]}`;
        if (!cache.has(address)) {
            cache.set(address, fn.apply(this, args));
        }
        return cache.get(address);
    };
    cached.cache = cache;
    return cached;
};
