import type * as svgs from '@sidetalk/svgs';

export const bytalkIconsName = {
    '24-Last-Heard-From': '24-Last-Heard-From',
    '28-Minimize': '28-Minimize',
    '56-mobile': '56-mobile',
    'aboutme-full': 'aboutme-full',
    'ad-print': 'ad-print',
    'ad-reach': 'ad-reach',
    'add-rule': 'add-rule',
    'agendamento-menu': 'agendamento-menu',
    'app-alerts': 'app-alerts',
    'app-billing': 'app-billing',
    'app-calculator': 'app-calculator',
    'app-chat': 'app-chat',
    'app-click2call': 'app-click2call',
    'app-click2chat': 'app-click2chat',
    'app-credits': 'app-credits',
    'app-edit-user': 'app-edit-user',
    'app-gmail': 'app-gmail',
    'app-inbound': 'app-inbound',
    'app-menu-ivr': 'app-menu-ivr',
    'app-notebook': 'app-notebook',
    'app-outbound': 'app-outbound',
    'app-pbx': 'app-pbx',
    'app-performance': 'app-performance',
    'app-queues': 'app-queues',
    'app-reports': 'app-reports',
    'app-schedule': 'app-schedule',
    'app-settings': 'app-settings',
    'app-sms': 'app-sms',
    'app-template': 'app-template',
    'app-user-management': 'app-user-management',
    'app-video2call': 'app-video2call',
    'app-web2call': 'app-web2call',
    'apps-menu': 'apps-menu',
    'arrow-close': 'arrow-close',
    'arrow-down-left': 'arrow-down-left',
    'arrow-down': 'arrow-down',
    'arrow-left': 'arrow-left',
    'arrow-open': 'arrow-open',
    'arrow-right-circle': 'arrow-right-circle',
    'arrow-right': 'arrow-right',
    'arrow-top-right': 'arrow-top-right',
    'arrow-up-left': 'arrow-up-left',
    'arrow-up-right': 'arrow-up-right',
    'arrow-up': 'arrow-up',
    'average-cost-per-click': 'average-cost-per-click',
    'average-cost': 'average-cost',
    'blank-campaign': 'blank-campaign',
    'block-button': 'block-button',
    'block-card-with-image': 'block-card-with-image',
    'block-code': 'block-code',
    'block-divisor': 'block-divisor',
    'block-follow-social-network': 'block-follow-social-network',
    'block-footer': 'block-footer',
    'block-group-of-images': 'block-group-of-images',
    'block-hero': 'block-hero',
    'block-image-and-text': 'block-image-and-text',
    'block-image': 'block-image',
    'block-links-menu': 'block-links-menu',
    'block-share-social-network': 'block-share-social-network',
    'block-text': 'block-text',
    'block-video': 'block-video',
    'browser-language': 'browser-language',
    'browser-resolution': 'browser-resolution',
    'browser-version': 'browser-version',
    'call-button': 'call-button',
    'call-contact': 'call-contact',
    'campaign-click2call-small': 'campaign-click2call-small',
    'campaign-click2call': 'campaign-click2call',
    'campaign-email-small': 'campaign-email-small',
    'campaign-email': 'campaign-email',
    'campaign-landing-small': 'campaign-landing-small',
    'campaign-landing': 'campaign-landing',
    'campaign-messenger-small': 'campaign-messenger-small',
    'campaign-messenger': 'campaign-messenger',
    'campaign-pause': 'campaign-pause',
    'campaign-sms-small': 'campaign-sms-small',
    'campaign-sms': 'campaign-sms',
    'campaign-standby': 'campaign-standby',
    'campaign-voice-small': 'campaign-voice-small',
    'campaign-voice': 'campaign-voice',
    'campaign-web2lead-small': 'campaign-web2lead-small',
    'campaign-web2lead': 'campaign-web2lead',
    'campaign-website-small': 'campaign-website-small',
    'campaign-website': 'campaign-website',
    'chamada-decorreer': 'chamada-decorreer path1 path2',
    'chamada-iniciar': 'chamada-iniciar path1 path2',
    'chamada-mute': 'chamada-mute',
    'chamada-mute1': 'chamada-mute1',
    'chamada-pause': 'chamada-pause',
    'chamada-pause1': 'chamada-pause1',
    'chamada-teclado': 'chamada-teclado',
    'chamada-teclado1': 'chamada-teclado1',
    'chamada-terminar': 'chamada-terminar path1 path2',
    'chamada-transfer': 'chamada-transfer',
    'chamada-transfer1': 'chamada-transfer1',
    'chat-app': 'chat-app',
    'chat-contact': 'chat-contact',
    'chat-send': 'chat-send',
    'chat-settings': 'chat-settings',
    'check-on': 'check-on',
    'check-parcial': 'check-parcial',
    'circle-minimize': 'circle-minimize',
    'classification-bad-call': 'classification-bad-call',
    'classification-no-sale': 'classification-no-sale',
    'classification-phone-problem': 'classification-phone-problem',
    'classification-prank': 'classification-prank',
    'classification-refused': 'classification-refused',
    'classification-sale': 'classification-sale',
    'classification-scheduling': 'classification-scheduling',
    'close-details': 'close-details',
    'close-window': 'close-window',
    'company-small': 'company-small',
    'contact-2': 'contact-2',
    'contact-count': 'contact-count',
    'contact-lists': 'contact-lists',
    'contact-menu': 'contact-menu',
    'contact-old': 'contact-old',
    'contact-source': 'contact-source',
    'contact-status': 'contact-status',
    'contacto-chamada': 'contacto-chamada',
    'contacto-chat': 'contacto-chat',
    'contacto-outbound-copy': 'contacto-outbound-copy',
    'contacto-outbound': 'contacto-outbound',
    'contacto-perdido': 'contacto-perdido',
    'contactos-pessoal': 'contactos-pessoal',
    'contacts-bytalk-copy3x': 'contacts-bytalk-copy3x',
    'contaxcts-phone-copy3x': 'contaxcts-phone-copy3x',
    'conversations-total': 'conversations-total',
    'credits-removed': 'credits-removed',
    'credits-renew': 'credits-renew',
    'credits-success': 'credits-success',
    'dashboard-calls': 'dashboard-calls',
    'dashboard-sales': 'dashboard-sales',
    'default-user': 'default-user',
    'device-brand': 'device-brand',
    'dialer-email': 'dialer-email',
    'dialer-sms': 'dialer-sms',
    'download-manual': 'download-manual',
    'duration-ticket': 'duration-ticket',
    'duration-window': 'duration-window',
    'edit-html': 'edit-html',
    'email-2': 'email-2',
    'email-button': 'email-button',
    'email-button2': 'email-button2',
    'email-contact': 'email-contact',
    'end-call': 'end-call',
    'expired-package': 'expired-package',
    'facebook-full': 'facebook-full',
    'facebook-landing': 'facebook-landing',
    'first-contact-date': 'first-contact-date',
    'first-name': 'first-name',
    'first-seen': 'first-seen',
    'first-visit-date': 'first-visit-date',
    'flickr-full': 'flickr-full',
    'foursquare-full': 'foursquare-full',
    'googleplus-full': 'googleplus-full',
    'googleprofile-full': 'googleprofile-full',
    'gravatar-full': 'gravatar-full',
    'history-app': 'history-app',
    'history-visitor': 'history-visitor',
    'import_copy-paste': 'import_copy-paste',
    'import-company': 'import-company',
    'import-contacts': 'import-contacts',
    'import-csv': 'import-csv',
    'import-file': 'import-file',
    'import-paragraph': 'import-paragraph',
    'import-text': 'import-text',
    'import-text1': 'import-text1',
    'instagram-full': 'instagram-full',
    'instagram-landing': 'instagram-landing',
    'interaction-call-inbound': 'interaction-call-inbound',
    'interaction-call-outbound': 'interaction-call-outbound',
    'interaction-call': 'interaction-call',
    'interaction-chat-inbound': 'interaction-chat-inbound',
    'interaction-chat-outbound': 'interaction-chat-outbound',
    'interaction-chat': 'interaction-chat',
    'interaction-email-inbound': 'interaction-email-inbound',
    'interaction-email-outbound': 'interaction-email-outbound',
    'interaction-email': 'interaction-email',
    'interaction-form': 'interaction-form',
    'interaction-sms-inbound': 'interaction-sms-inbound',
    'interaction-sms-outbound': 'interaction-sms-outbound',
    'interaction-sms': 'interaction-sms',
    'interaction-video-inbound': 'interaction-video-inbound',
    'interaction-video-outbound': 'interaction-video-outbound',
    'interaction-video': 'interaction-video',
    'interaction-voicemail-inbound': 'interaction-voicemail-inbound',
    'interaction-voicemail-outbound': 'interaction-voicemail-outbound',
    'interaction-voicemail': 'interaction-voicemail',
    'janelas-expandidas': 'janelas-expandidas',
    'klout-full': 'klout-full',
    'language-override': 'language-override',
    'last-contact-date': 'last-contact-date',
    'last-contacted': 'last-contacted',
    'last-contacted2': 'last-contacted2',
    'last-heard-from': 'last-heard-from',
    'last-login': 'last-login',
    'last-name': 'last-name',
    'last-seen-mobile': 'last-seen-mobile',
    'last-seen': 'last-seen',
    'last-visit-date': 'last-visit-date',
    'linkedin-full': 'linkedin-full',
    'linkedin-landing': 'linkedin-landing',
    'list-vouchers': 'list-vouchers',
    'marcacoes-new': 'marcacoes-new',
    'menu-campanhas': 'menu-campanhas',
    'menu-chamadas': 'menu-chamadas',
    'menu-chat': 'menu-chat',
    'menu-contactos': 'menu-contactos',
    'menu-live': 'menu-live',
    'menu-marcacoes': 'menu-marcacoes',
    'minimize-2': 'minimize-2',
    'mobile-1': 'mobile-1',
    'mobile-notifications': 'mobile-notifications',
    'more-info-off': 'more-info-off',
    'more-info-on': 'more-info-on',
    'mute-2': 'mute-2',
    'myspace-full': 'myspace-full',
    'new-contact': 'new-contact',
    'new-contacts': 'new-contacts',
    'no-icon': 'no-icon',
    'no-results': 'no-results',
    'on-hold-ticket': 'on-hold-ticket',
    'on-hold-window': 'on-hold-window',
    'open-details': 'open-details',
    'open-in-window': 'open-in-window',
    'package-buy': 'package-buy',
    'package-subscription': 'package-subscription',
    'pageviews-total': 'pageviews-total',
    'performance-charts': 'performance-charts',
    'pinterest-full': 'pinterest-full',
    'Pinterest-landing': 'Pinterest-landing',
    'preview-desktop': 'preview-desktop',
    'preview-mobile': 'preview-mobile',
    'preview-tablet': 'preview-tablet',
    'quora-full': 'quora-full',
    'report-download': 'report-download',
    'schedule-contact': 'schedule-contact',
    'screen-cast-button': 'screen-cast-button',
    'section-adcenter': 'section-adcenter',
    'section-console': 'section-console',
    'section-dashboard': 'section-dashboard',
    'section-leads': 'section-leads',
    'section-marketing': 'section-marketing',
    'section-support': 'section-support',
    'select-target': 'select-target',
    'select-template': 'select-template',
    'sessions-total': 'sessions-total',
    'settings-bar': 'settings-bar',
    'signed-up': 'signed-up',
    'sms-button': 'sms-button',
    'sms-contact': 'sms-contact',
    'start-ticket': 'start-ticket',
    'start-window': 'start-window',
    'sync-large3x': 'sync-large3x',
    'sync-small-copy3x': 'sync-small-copy3x',
    'talk-click': 'talk-click',
    'talk-failed': 'talk-failed',
    'talk-read': 'talk-read',
    'talk-read2': 'talk-read2',
    'talk-sent': 'talk-sent',
    'talk-total': 'talk-total',
    'talk-trash': 'talk-trash',
    'talk-unsubscribe': 'talk-unsubscribe',
    'template-empty': 'template-empty',
    'ticket-number': 'ticket-number',
    'todos-contactos': 'todos-contactos',
    'touchpoint-email': 'touchpoint-email',
    'touchpoint-phone': 'touchpoint-phone',
    'touchpoint-user': 'touchpoint-user',
    'touchpoint-web': 'touchpoint-web',
    'twitter-followers': 'twitter-followers',
    'twitter-full': 'twitter-full',
    'twitter-landing': 'twitter-landing',
    'un-mute': 'un-mute',
    'un-pause': 'un-pause',
    'unsubscribe-all': 'unsubscribe-all',
    'vimeo-full': 'vimeo-full',
    'visit-count': 'visit-count',
    'visitors-total': 'visitors-total',
    'voucher-icon': 'voucher-icon',
    'waiting-confirmation': 'waiting-confirmation',
    'web-notifications': 'web-notifications',
    'web-sessions': 'web-sessions',
    'widget-call': 'widget-call',
    'widget-chat': 'widget-chat',
    'widget-video': 'widget-video',
    'widget-web': 'widget-web',
    'work-phone': 'work-phone',
    'wrapup-code': 'wrapup-code',
    'youtube-full': 'youtube-full',
    'zip-code': 'zip-code',
    aboutme: 'aboutme',
    add: 'add',
    addcompany: 'addcompany',
    addcontact: 'addcontact',
    adicionar: 'adicionar',
    age: 'age',
    agendamento: 'agendamento',
    alerts: 'alerts',
    anonymous: 'anonymous path1 path2',
    answer: 'answer',
    apps: 'apps',
    attach: 'attach',
    audio: 'audio',
    birthday: 'birthday',
    blank: 'blank',
    block: 'block',
    browser: 'browser',
    calendar: 'calendar',
    camera: 'camera',
    city: 'city',
    clock: 'clock',
    close: 'close',
    comment: 'comment',
    company: 'company',
    conference: 'conference',
    conference2: 'conference2',
    contact: 'contact',
    country: 'country',
    credits: 'credits',
    crm: 'crm',
    crm1: 'crm1',
    csv: 'csv',
    default: 'default',
    delete: 'delete',
    duration: 'duration',
    edit: 'edit',
    email: 'email',
    emoji: 'emoji',
    empresa: 'empresa',
    error: 'error',
    excel: 'excel',
    export: 'export',
    extensions: 'extensions',
    facebook: 'facebook',
    filter: 'filter',
    flickr: 'flickr',
    forward: 'forward',
    foursquare: 'foursquare',
    gender: 'gender',
    googleads: 'googleads',
    googleplus: 'googleplus',
    googleprofile: 'googleprofile',
    gravatar: 'gravatar',
    hangout: 'hangout',
    history: 'history',
    hold: 'hold',
    id: 'id',
    import_excel: 'import_excel',
    importcontact: 'importcontact',
    instagram: 'instagram',
    ip: 'ip',
    isp: 'isp',
    keypad: 'keypad',
    klout: 'klout',
    link: 'link',
    linkedin: 'linkedin',
    list: 'list',
    listas: 'listas',
    lists: 'lists',
    live: 'live',
    Live: 'Live',
    logo: 'logo',
    maximize: 'maximize',
    minimize: 'minimize',
    mobile: 'mobile',
    moji: 'moji',
    mood: 'mood',
    mute: 'mute',
    myspace: 'myspace',
    note: 'note',
    nps: 'nps',
    number: 'number',
    onboarding: 'onboarding',
    ongoing_call: 'ongoing_call',
    order: 'order',
    os: 'os',
    pageviews: 'pageviews',
    password: 'password',
    pause1: 'pause1',
    pbx: 'pbx',
    performance: 'performance',
    pesquisar: 'pesquisar',
    phone: 'phone',
    picker: 'picker',
    picture: 'picture',
    pin: 'pin',
    pinterest: 'pinterest',
    play: 'play',
    plus: 'plus',
    preferences: 'preferences',
    products: 'products',
    profile: 'profile',
    queue: 'queue',
    quora: 'quora',
    received: 'received',
    redirect: 'redirect',
    refresh: 'refresh',
    Saldo: 'Saldo',
    salesforce: 'salesforce',
    salut: 'salut',
    schedule: 'schedule',
    scheduled: 'scheduled',
    search: 'search',
    segmento: 'segmento path1 path2 path3 path4',
    sent: 'sent',
    settings: 'settings',
    state: 'state',
    statistics: 'statistics',
    stop: 'stop',
    street: 'street',
    subscriptions: 'subscriptions',
    tag: 'tag',
    tags: 'tags',
    talk: 'talk',
    target: 'target',
    team: 'team',
    teammates: 'teammates',
    teams: 'teams',
    template: 'template',
    timezone: 'timezone',
    title: 'title',
    trash: 'trash',
    twitter: 'twitter',
    unsubscribe: 'unsubscribe',
    url: 'url',
    vimeo: 'vimeo',
    voice: 'voice',
    voltar: 'voltar',
    voucher: 'voucher',
    wand: 'wand',
    web: 'web',
    website: 'website',
    whatsapps: 'whatsapps',
    windows: 'windows',
    youtube: 'youtube',
} as const;

export type BytalkClassNamesIcons = keyof typeof bytalkIconsName;

export const bysideIconsName = {
    'active-calls': 'active-calls',
    'active-chats': 'active-chats',
    'active-contacts': 'active-contacts',
    'app-calculator': 'app-calculator',
    'app-call-all': 'app-call-all',
    'app-call-falando-out': 'app-call-falando-out',
    'app-call-loginin': 'app-call-loginin',
    'app-call-on-hold': 'app-call-on-hold',
    'app-call-pause': 'app-call-pause',
    'app-call-stop': 'app-call-stop',
    'app-call-talking': 'app-call-talking',
    'app-call-total-operators': 'app-call-total-operators',
    'app-call-waiting': 'app-call-waiting',
    'app-chat': 'app-chat',
    'app-click2call': 'app-click2call',
    'app-notebook': 'app-notebook',
    'app-pbx': 'app-pbx',
    'app-performance': 'app-performance',
    'app-reports': 'app-reports',
    'app-user-management': 'app-user-management',
    'app-web2call': 'app-web2call',
    'arrow-close': 'arrow-close',
    'arrow-down': 'arrow-down',
    'arrow-large': 'arrow-large',
    'arrow-left': 'arrow-left',
    'arrow-open': 'arrow-open',
    'arrow-right': 'arrow-right',
    'arrow-small': 'arrow-small',
    'arrow-up': 'arrow-up',
    'attended-transfer': 'attended-transfer',
    'audio-report': 'audio-report',
    'audios-app': 'audios-app',
    'available-operators': 'available-operators',
    'bad-call': 'bad-call',
    'bar-graph': 'bar-graph',
    'bar-stacked-graph': 'bar-stacked-graph',
    'blind-transfer': 'blind-transfer',
    'broadcast-bigger': 'broadcast-bigger',
    'broadcast-camera': 'broadcast-camera',
    'broadcast-error': 'broadcast-error',
    'broadcast-likes': 'broadcast-likes',
    'broadcast-messages': 'broadcast-messages',
    'broadcast-mute': 'broadcast-mute',
    'broadcast-ok': 'broadcast-ok',
    'broadcast-online': 'broadcast-online',
    'broadcast-pause': 'broadcast-pause',
    'broadcast-play': 'broadcast-play',
    'broadcast-record': 'broadcast-record',
    'broadcast-smaller': 'broadcast-smaller',
    'broadcast-stop': 'broadcast-stop',
    'broadcast-unmute': 'broadcast-unmute',
    'broadcast-viewers': 'broadcast-viewers',
    'byside-small': 'byside-small path1 path2 path3 path4 path5 path6 path7 path8 path9',
    'byside-vertical': 'byside-vertical path1 path2 path3 path4 path5 path6 path7 path8 path9 path10',
    'call-active': 'call-active',
    'call-contact': 'call-contact',
    'call-free': 'call-free',
    'call-login-in': 'call-login-in',
    'call-on-hold': 'call-on-hold',
    'call-paused': 'call-paused',
    'call-scheduled': 'call-scheduled',
    'call-speaking': 'call-speaking',
    'campaign-click': 'campaign-click',
    'campaign-click1': 'campaign-click1',
    'campaign-closed': 'campaign-closed',
    'campaign-closed1': 'campaign-closed1',
    'campaign-contacts': 'campaign-contacts',
    'campaign-failed': 'campaign-failed',
    'campaign-failed1': 'campaign-failed1',
    'campaign-read': 'campaign-read',
    'campaign-read1': 'campaign-read1',
    'campaign-sent': 'campaign-sent',
    'campaign-sent1': 'campaign-sent1',
    'campaign-success': 'campaign-success',
    'campaign-success1': 'campaign-success1',
    'campaign-to-send': 'campaign-to-send',
    'campaign-total': 'campaign-total',
    'campaign-total1': 'campaign-total1',
    'campaign-unsubscribe': 'campaign-unsubscribe',
    'campaign-unsubscribe1': 'campaign-unsubscribe1',
    'campaign-waiting-confirmation': 'campaign-waiting-confirmation',
    'campaign-waiting-confirmation2': 'campaign-waiting-confirmation2',
    'campaigns-app': 'campaigns-app',
    'chat-app': 'chat-app',
    'chat-contact': 'chat-contact',
    'chat-icon': 'chat-icon path1 path2 path3',
    'chat-inbound': 'chat-inbound',
    'chat-interno': 'chat-interno',
    'chat-outbound': 'chat-outbound',
    'chat-settings': 'chat-settings',
    'chatbot-out': 'chatbot-out',
    'chatbot-web': 'chatbot-web',
    'chatbot-whatsapp': 'chatbot-whatsapp',
    'check-off-disabled': 'check-off-disabled',
    'check-off': 'check-off',
    'check-on-disabled': 'check-on-disabled',
    'check-on': 'check-on',
    'check-partial-disabled': 'check-partial-disabled',
    'check-partial': 'check-partial',
    'circle-close': 'circle-close',
    'circle-minimize': 'circle-minimize',
    'click2call-new': 'click2call-new',
    'coloured-rec': 'coloured-rec',
    'column-graph': 'column-graph',
    'column-stacked-graph': 'column-stacked-graph',
    'custom-integration': 'custom-integration',
    'dashboard-sales': 'dashboard-sales',
    'dashboard-settings': 'dashboard-settings',
    'dont-show': 'dont-show',
    'download-small': 'download-small',
    'due-date': 'due-date',
    'ecommerce-products-list': 'ecommerce-products-list',
    'email-small': 'email-small path1 path2',
    'email-ticketing': 'email-ticketing',
    'ended-broadcasts': 'ended-broadcasts',
    'export-audio': 'export-audio',
    'export-custom': 'export-custom',
    'export-customize': 'export-customize',
    'export-preview': 'export-preview',
    'export-report': 'export-report',
    'export-rule': 'export-rule',
    'export-transcript': 'export-transcript',
    'exports-app': 'exports-app',
    'expression-plus': 'expression-plus',
    'external-contacts': 'external-contacts',
    'file-audio': 'file-audio',
    'file-transfer': 'file-transfer',
    'file-video': 'file-video',
    'filter-sort': 'filter-sort',
    'first-name': 'first-name',
    'form-on': 'form-on',
    'forms-icon': 'forms-icon path1 path2 path3 path4 path5 path6 path7 path8 path9 path10 path11',
    'import-others': 'import-others',
    'inbound-cell': 'inbound-cell',
    'inbound-icon': 'inbound-icon path1 path2 path3 path4',
    'inbound-skill': 'inbound-skill',
    'info-2': 'info-2',
    'integration-icon': 'integration-icon',
    'landing-page': 'landing-page',
    'last-contact-date': 'last-contact-date',
    'last-name': 'last-name',
    'leads-app': 'leads-app',
    'line-area-graph': 'line-area-graph',
    'line-graph-2': 'line-graph-2',
    'line-graph': 'line-graph',
    'live-active': 'live-active',
    'live-pages': 'live-pages',
    'live-queue': 'live-queue',
    'live-schedule': 'live-schedule',
    'live-visitors': 'live-visitors',
    'livechat-out': 'livechat-out',
    'livechat-web': 'livechat-web',
    'livechat-whatsapp': 'livechat-whatsapp',
    'llive-waiting': 'llive-waiting',
    'map-graph': 'map-graph',
    'maximize-fullscreen': 'maximize-fullscreen',
    'minimize-fullscreen': 'minimize-fullscreen',
    'mobile-notifications': 'mobile-notifications',
    'New-broadcast': 'New-broadcast',
    'new-messages': 'new-messages',
    'no-icon': 'no-icon',
    'no-sell': 'no-sell',
    'not-available': 'not-available',
    'ongoing-broadcast': 'ongoing-broadcast',
    'open-details': 'open-details',
    'open-in-window': 'open-in-window',
    'open-url': 'open-url',
    'order-down': 'order-down',
    'order-up': 'order-up',
    'outbound-icon': 'outbound-icon path1 path2 path3 path4',
    'outbound-skill': 'outbound-skill',
    'pause-call': 'pause-call',
    'pause-icon': 'pause-icon path1 path2 path3 path4',
    'performance-active': 'performance-active',
    'performance-free': 'performance-free',
    'performance-hold': 'performance-hold',
    'performance-pause': 'performance-pause',
    'performance-speaking': 'performance-speaking',
    'performance-waiting': 'performance-waiting',
    'phone-inbound': 'phone-inbound',
    'phone-outbound': 'phone-outbound',
    'pie-graph': 'pie-graph',
    'play-icon': 'play-icon path1 path2 path3',
    'play-large': 'play-large',
    'play-stream': 'play-stream',
    'preview-msg': 'preview-msg',
    'public-dashboard': 'public-dashboard',
    'push-notifications': 'push-notifications',
    'radio-off-disabled': 'radio-off-disabled',
    'radio-off': 'radio-off',
    'radio-on-disabled': 'radio-on-disabled',
    'radio-on': 'radio-on',
    'rec-pause-coloured': 'rec-pause-coloured path1 path2 path3',
    'rec-pause': 'rec-pause',
    'rec-resume': 'rec-resume',
    'record-stream': 'record-stream',
    'report-download': 'report-download',
    'report-icon': 'report-icon',
    'schedule-calls': 'schedule-calls',
    'screen-sharing-pause': 'screen-sharing-pause',
    'screen-sharing-stop': 'screen-sharing-stop',
    'screen-sharing': 'screen-sharing',
    'section-adcenter': 'section-adcenter',
    'section-console': 'section-console',
    'section-dashboard': 'section-dashboard',
    'section-leads': 'section-leads',
    'section-support': 'section-support',
    'settings-small': 'settings-small',
    'show-less': 'show-less',
    'team-icon': 'team-icon',
    'ticket-number': 'ticket-number',
    'toggle-on': 'toggle-on path1 path2',
    'toogle-button': 'toogle-button',
    'toogle-off': 'toogle-off',
    'total-calls': 'total-calls',
    'total-classified': 'total-classified',
    'total-sells': 'total-sells',
    'transcription-icon': 'transcription-icon',
    'transfer-number': 'transfer-number',
    'transfer-skill': 'transfer-skill',
    'transfer-view': 'transfer-view',
    'trophy-1': 'trophy-1',
    'trophy-2': 'trophy-2',
    'trophy-3': 'trophy-3',
    'trophy-4': 'trophy-4',
    'trophy-5': 'trophy-5',
    'trophy-6': 'trophy-6',
    'trophy-7': 'trophy-7',
    'trophy-8': 'trophy-8',
    'user-settings': 'user-settings',
    'video-broadcast': 'video-broadcast',
    'video-call': 'video-call',
    'voice-icon': 'voice-icon path1 path2 path3',
    'voice-inbound-new': 'voice-inbound-new',
    'voice-inbound': 'voice-inbound',
    'voice-outbound-new': 'voice-outbound-new',
    'voice-outbound': 'voice-outbound',
    'waiting-calls': 'waiting-calls',
    'waiting-in-queue': 'waiting-in-queue',
    'webphone-off': 'webphone-off',
    'webphone-on': 'webphone-on',
    'website-campaign': 'website-campaign',
    'wrapup-code': 'wrapup-code',
    'zip-code': 'zip-code',
    ai: 'ai',
    alert: 'alert',
    alerts: 'alerts',
    anonymous: 'anonymous path1 path2',
    apps: 'apps',
    archive: 'archive',
    asc: 'asc',
    ascending: 'ascending',
    available: 'available',
    billing: 'billing',
    birthday: 'birthday',
    bold: 'bold',
    broadcast: 'broadcast',
    browser: 'browser',
    byside: 'byside',
    calendar: 'calendar',
    campaigns: 'campaigns',
    capacity: 'capacity',
    cdp: 'cdp',
    cellphone: 'cellphone',
    channels: 'channels',
    chat: 'chat',
    chatbot: 'chatbot',
    city: 'city',
    click2call: 'click2call',
    close: 'close',
    company: 'company',
    conference: 'conference',
    consola: 'consola',
    country: 'country',
    dashboard: 'dashboard',
    delete: 'delete',
    des: 'des',
    descending: 'descending',
    download: 'download',
    dropdown: 'dropdown',
    duplicate: 'duplicate',
    duration: 'duration',
    email: 'email',
    emoji: 'emoji',
    excel: 'excel',
    export: 'export',
    exports: 'exports',
    external: 'external path1 path2 path3 path4 path5 path6 path7 path8 path9 path10 path11',
    file: 'file',
    filter: 'filter',
    filter2: 'filter2',
    folder: 'folder',
    folders: 'folders',
    font: 'font',
    form: 'form',
    forward: 'forward',
    gamification: 'gamification',
    gender: 'gender',
    grid: 'grid',
    hangup: 'hangup',
    image: 'image',
    import_csv: 'import_csv',
    import_xls: 'import_xls',
    info: 'info',
    italic: 'italic',
    leads: 'leads',
    lighthouse: 'lighthouse',
    link: 'link',
    list: 'list',
    live: 'live',
    logo: 'logo',
    marketing: 'marketing',
    maximize: 'maximize',
    messenger: 'messenger',
    minimize: 'minimize',
    mood: 'mood',
    mute: 'mute',
    mute1: 'mute1',
    mute2: 'mute2',
    new_company: 'new_company',
    new_contact: 'new_contact',
    newfolder: 'newfolder',
    note: 'note',
    notifications: 'notifications',
    nps: 'nps',
    number: 'number',
    operator: 'operator',
    pages: 'pages',
    paragraph: 'paragraph',
    pause: 'pause path1 path2 path3',
    pause1: 'pause1',
    pbx: 'pbx',
    pdf: 'pdf',
    pencil: 'pencil',
    percentagem: 'percentagem',
    performance: 'performance',
    phone: 'phone',
    Play: 'Play path1 path2',
    plus: 'plus',
    powerpoint: 'powerpoint',
    racio: 'racio',
    rec: 'rec',
    refresh: 'refresh',
    refuse: 'refuse',
    reorder: 'reorder',
    schedule: 'schedule',
    search: 'search',
    sell: 'sell',
    settings: 'settings',
    share: 'share',
    shared: 'shared',
    show: 'show',
    sms: 'sms',
    standby: 'standby',
    state: 'state',
    street: 'street',
    subscriptions: 'subscriptions',
    tags: 'tags',
    team: 'team',
    telefonia: 'telefonia',
    text: 'text',
    text1: 'text1',
    time: 'time',
    transfer: 'transfer',
    trash: 'trash',
    trick: 'trick',
    underline: 'underline',
    unmute: 'unmute',
    unmute1: 'unmute1',
    upload: 'upload',
    user: 'user',
    videoconf: 'videoconf',
    visible: 'visible',
    visitors: 'visitors',
    voice: 'voice',
    wand: 'wand',
    web2lead: 'web2lead',
    website: 'website',
    whatsapp: 'whatsapp',
    word: 'word',
    wrapup: 'wrapup',
} as const;

export type BysideClassNamesIcons = keyof typeof bysideIconsName;

export type SVGNamesIcons = keyof typeof svgs;
