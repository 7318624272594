import { useState, useCallback, useEffect } from 'react';

export function useHasOverflowed<T extends HTMLElement = HTMLElement>(): [(node: T) => void, boolean] {
    const [hasOverflowed, setHasOverflowed] = useState(false);
    const [node, setNode] = useState<T | null>(null);
    const nodeRef = useCallback((node: T) => {
        if (node instanceof HTMLElement) {
            setNode(node);
        }
    }, []);

    useEffect(() => {
        if (!node) {
            return;
        }

        const calculateOverflow = () => {
            const hasOverflow = node.scrollHeight > node.clientHeight || node.scrollWidth > node.clientWidth;
            setHasOverflowed(hasOverflow);
        };

        const resizeObserver = new ResizeObserver(calculateOverflow);

        resizeObserver.observe(node);
        calculateOverflow();

        return () => {
            resizeObserver.disconnect();
        };
    }, [node]);

    return [nodeRef, hasOverflowed];
}
