import { useEffect, useRef, type RefObject } from 'react';

export const useOnOutsideClick = <T extends HTMLElement = HTMLElement>(
    enabled = true,
    ref: RefObject<T>,
    callback: (event: MouseEvent | TouchEvent | FocusEvent) => void,
): void => {
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        if (!enabled) return;

        const controller = new AbortController();

        document.addEventListener(
            'mousedown',
            (event) => {
                const target = event.target as Node;

                // Do nothing if the target is not connected element with document
                if (!target?.isConnected) {
                    return;
                }

                const isOutside = ref.current && !ref.current.contains(target);

                if (isOutside) {
                    callbackRef.current(event);
                }
            },
            {
                signal: controller.signal,
            },
        );

        document.addEventListener(
            'focusin',
            (event) => {
                if (ref.current && !ref.current.contains(document.activeElement)) {
                    callbackRef.current(event);
                }
            },
            {
                signal: controller.signal,
            },
        );

        return () => {
            controller.abort();
        };
    }, [enabled, ref]);
};
